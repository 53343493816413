export default {
  totalAmountMarginTop: '0',
  loanCalculatorBorderWidth: '2px',
  loanCalculatorValueColor: 'var(--green)',
  summaryButtonContent: '✓ ',
  summaryButtonSelectedBackground: 'var(--white)',
  summaryButtonSelectedColor: 'var(--teal)',
  userAgreementMarginTop: '.5rem',

};
