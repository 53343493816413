import YEARSELECT from '../../../../localizations/config/SE/sambla/year-select';
import TOTALAMOUNTSELECT from '../../../../localizations/config/SE/sambla/totalAmount-select';
import textResource from '../../../../utils/text-resources/sambla/SE/messages';
import submit from '../../../../mappers/FI/application';

const tooltip = (text) => {
  const temp = {
    trigger: 'focus',
    text,
    width: 'fill',
    position: 'right',
  };
  return temp;
};

const data = {
  general: {
    mapper: submit,
  },
  links: {
    quickReapply: '/returning-customer/',
    quickReapplyEng: '/en/returning-customer/',
    userAgreementEng: 'https://www.sambla.se/en/user-agreement/',
    userAgreement: 'https://www.sambla.fi/kaeyttoeehdot/',
    dataPolicy: 'https://www.sambla.fi/tietosuojaseloste-evaesteet/',
    dataPolicyEng: 'https://www.sambla.se/en/data-protection-policy/',
  },
  slides: [
    {
      title: '',
      coApplicantSlide: false,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'Select',
          props: {
            name: 'totalAmount',
            label: textResource.applicationLoanAmountLabel,
            labelOutside: true,
            options: TOTALAMOUNTSELECT,
            tooltip: tooltip(textResource.applicationLoanAmountTooltip),
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          props: {
            name: 'repaymentTime',
            label: textResource.purposeHeaderText,
            labelOutside: true,
            options: YEARSELECT,
            tooltip: tooltip(textResource.purposeTooltip),
            layout: {
              start: 2,
              end: 3,
            },
          },
        },
        {
          component: 'MonthlyCost',
          props: {
            label: textResource.monthlyCostApproximateMonthlyCostLabel,
            costLabel: textResource.monthlyCostApproximateCostLabel,
            approximateMonthlyCostDesktop: textResource.monthlyCostApproximateCostPerMonthDesktop,
            approximateMonthlyCostMobile: textResource.monthlyCostApproximateCostPerMonthMobile,
            approximateMonthlyCostYears: textResource.monthlyCostApproximateCostLabelYears,
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.repaymentTimeWarning,
            transparent: true,
            visibleWhen: 'return(formState.values.repaymentTime > 15)',
            margin: false,
            center: false,
            special: true,
            layout: {
              start: 3,
              end: 4,
            },
          },
        },
        {
          component: 'TotalCost',
          props: {
            label: textResource.totalCostApproximateCostLabel,
            costLabel: textResource.monthlyCostApproximateCostLabel,
            approximateTotalCost: textResource.totalCostApproximateCost,
            layout: {
              start: 4,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'phoneNumber',
            label: textResource.phoneNumberLabel,
            type: 'tel',
            placeholder: textResource.phoneNumberPlaceholder,
            tooltip: tooltip(textResource.phoneNumberTooltip),
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'applicant',
          props: {
            name: 'email',
            label: textResource.emailLabel,
            placeholder: textResource.emailPlaceholder,
            tooltip: tooltip(textResource.emailTooltip),
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'CreditCheckConsent',
          form: 'Checkbox',
          props: {
            name: 'creditCheckConsent',
            text: textResource.acceptCreditCheck,
            linkText: textResource.samblaPlusReadMore,
            links: 'https://www.sambla.fi/kaeyttoeehdot/',
            linkInText: false,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            destinationPath: '/fi/omalainaform/',
            sendAbandonCartEvent: true,
            label: textResource.nextSlide,
            ctaBtnStyle: true,
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
      ],
    },
  ],
};

export default data;
